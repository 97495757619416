<template>
<div class="box" ref="box">
<h1 class="h1_title">证书认证</h1>
<div class="certificate" ref="certificate">
      <div class="conte">
        <div class="topTab" ref="topTab"><nav v-for="(item,i) in ['按证书编号查询','按姓名查询']" :key="item" @click="tab_(i)"><i></i>{{item}}</nav></div>
        <input type="text" :placeholder="index == 0? '请输入证书编号':'请输入您的姓名'">
        <button>点此查询证书</button>
      </div>
    </div>
</div>
      <signUp class="signUp"></signUp>
</template>

<script setup>
import signUp from '@/components/phonesignUp.vue'
import { ref,onMounted } from "vue";

const topTab = ref('')
const index = ref(0)
// 点击切换tab栏
let tab_ = (ii) => {
  const navs = topTab.value.children
  for (let index = 0; index < navs.length; index++) {
        navs[index].children[0].style.opacity = 0
        navs[index].style.color = '#5F5F5F'
  }
  navs[ii].children[0].style.opacity = 1
  navs[ii].style.color = '#174994'
  index.value = ii
}

const box = ref(null)
onMounted(() => {
  tab_(0)
  setTimeout(() => {
    box.value.style.transform = 'translateY(0)'
    box.value.style.opacity = '1'
    },500)
})
</script>
<style lang="less" scoped>
@maxw:128rem;
.box{
  width: 100%;
  margin: 0 20/@maxw;
  transform: translateY(800px);
  opacity: 0;
  transition: ease .8s;
  }
 .h1_title{
    box-sizing: border-box;
    font-size: 39/@maxw;
    font-family: fantBold;
    font-weight: 300;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    margin: 0;
    padding: 0 0 30/@maxw 0;
  }
.certificate{
    cursor: pointer;
    margin-top: 30/@maxw;
    width: 100%;
    height: 467/@maxw;
    background-image: url("../../../assets/zhegnshuchax.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    
    >.conte{
      box-sizing: border-box;
      width: 688/@maxw;
      display: flex;
      flex-direction: column;
      align-items: center;
      .topTab{
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 60/@maxw;
        border-bottom: 2px solid #517DBE;
        nav{
          height: 100%;
          flex: 1;
          font-size: 27/@maxw;
          text-align: center;
          color: #5F5F5F;
          i{
            display: inline-block;
            width: 11/@maxw;
            height: 19/@maxw;
            background-image: url("http://chuantu.xyz/t6/742/1662015859x2728306369.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-right: 12/@maxw;
            opacity: 0;
            transition: ease .2s;
          }
        }
      }
      >input{
        box-sizing: border-box;
        width: 100%;
        height: 48/@maxw;
        margin: 63/@maxw 0 88/@maxw 0;
        border-radius: 10/@maxw;
        border:1px solid #707070;
        font-size: 27/@maxw;
        padding-left: 23/@maxw;
        color: #646464;
      }
      >input::placeholder {  color: #D0D0D0; }
      >button{
        width: 370/@maxw;
        height: 56/@maxw;
        background: linear-gradient(to right,#174994,#387DE2);
        color: #fff;
        border:none;
        border-radius: 10/@maxw;
        font-size: 27/@maxw;
        cursor: pointer;
        transition: ease .3s;
      }
      >button:hover{ 
            transform: translate(5px,6px);
          }
    }
  }
@media screen and (max-width:750px) {
@maxw_:750/100vw;
.signUp{
  display: none;
}
 .h1_title{
    font-size: 39/@maxw_ !important;
    border-bottom:2/@maxw_ solid #174994 !important;
    padding: 0 0 30/@maxw_ 0 !important;
  }
.certificate{
    margin-top: 30/@maxw_;
    height: 467/@maxw_;
    >.conte{
      width: 688/@maxw_;
      .topTab{
        height: 60/@maxw_;
        nav{
          font-size: 27/@maxw_;
          i{
            width: 11/@maxw_;
            height: 19/@maxw_;
            margin-right: 12/@maxw_;
          }
        }
      }
      >input{
        width: 90%;
        height: 48/@maxw_;
        margin: 63/@maxw_ 0 88/@maxw_ 0;
        border-radius: 10/@maxw_;
        font-size: 27/@maxw_;
        padding-left: 23/@maxw_;
      }
      >button{
        width: 370/@maxw_;
        height: 56/@maxw_;
        border-radius: 10/@maxw_;
        font-size: 27/@maxw_;
      }
    }
  }
}
</style>